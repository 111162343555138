@font-face {
  font-family: 'BobbyJSerif-Bold';
  src: url('fonts/BobbyJSerif-Bold.eot');
  src: url('fonts/BobbyJSerif-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/BobbyJSerif-Bold.ttf') format('truetype'),
  url('fonts/BobbyJSerif-Bold.woff') format('woff'),
  url('fonts/BobbyJSerif-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
