/* Colors */

$white: #fff;
$gray-100: #fbfaf6;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-850: #303030;
$gray-900: #212529;
$black: #000;

$blueH:   #1c355e;
$blueS:   #a2c5d3;
$brown:   #a49483;
$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #ecf3f6;

$primary:       $blueH;
$secondary:     $blueS;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-300;
$dark:          $gray-800;

.bg-brown{
background-color: $brown;
};

.text-brown{
  color: $brown;
};

.bg-blue-op50{
  background-color: rgba(28, 53, 94, 0.7);
}

.bg-brown-op50{
  background-color: rgba(164, 148, 131, 0.8);
}

.bg-white-op50{
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-op75{
  background-color: rgba(255, 255, 255, 0.75);
}

.bg-bluSS{
  background-color: #c6e9f7;
}

.bg-bluHH{
  background-color: #1c274f;
}

.react-date-picker__wrapper{
  border: 1px solid $primary !important;
}

.react-time-picker__wrapper{
  border: 1px solid $primary !important;
}


/* Body */
$body-bg: $white;

/* Shadow */

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);



/*inputs*/

$input-border-radius:                   none;
$input-border-radius-lg:                none;
$input-border-radius-sm:                none;
$input-border-color:                    $primary;
$input-box-shadow:                      none;
$input-focus-border-color:              none;
$input-focus-box-shadow:                none;

/* button */
$btn-border-radius:           none;
$btn-border-radius-lg:        none;
$btn-border-radius-sm:        none;
