
.my-font-underline{
  text-decoration: underline;
}

.my-font-sz-05 {
  font-size: 0.5em;
}

.my-font-sz-06 {
  font-size: 0.6em;
}

.my-font-sz-07 {
  font-size: 0.7em;
}
.my-font-sz-08 {
  font-size: 0.8em;
}

.my-font-sz-09 {
  font-size: 0.9em;
}

.my-font-sz-1 {
  font-size: 1em;
}

.my-font-sz-1-2 {
  font-size: 1.2em;
}

.my-font-sz-1-5 {
  font-size: 1.5em;
}

.my-font-sz-1-8 {
  font-size: 1.8em;
}

.my-font-sz-2 {
  font-size: 2em;
}

.my-font-sz-3 {
  font-size: 3em;
}

.my-font-sz-4 {
  font-size: 4em;
}

.my-font-sz-5 {
  font-size: 5em;
}

.my-font-sz-6 {
  font-size: 6em;
}

.my-font-sz-7 {
  font-size: 7em;
}

.my-font-sz-8 {
  font-size: 8em;
}

.my-font-sz-9 {
  font-size: 9em;
}

.my-font-sz-10 {
  font-size: 10em;
}

/* font-weight */

.my-font-wg-900 {
  font-weight: 900;
}

.my-font-wg-800 {
  font-weight: 800;
}

.my-font-wg-700 {
  font-weight: 700;
}

.my-font-wg-600 {
  font-weight: 600;
}

.my-font-wg-500 {
  font-weight: 500;
}

.my-font-wg-400 {
  font-weight: 400;
}

/* letter-spacing */

.my-font-ls-1{
  letter-spacing: 1px;
}
.my-font-ls-2{
  letter-spacing: 2px;
}
.my-font-ls-3{
  letter-spacing: 3px;
}
.my-font-ls-4{
  letter-spacing: 4px;
}


/* Family */

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.ffmontserrat {
  font-family: "Montserrat", sans-serif;
}

.ffbobby{
  font-family: "BobbyJSerif-Bold", sans-serif;
}

.ffdancing{
  font-family: "Dancing Script", cursive;
  font-weight: bold;
}
