@import "newBootstrap";
@import "fontFaces";
@import "fontStyle";
@import "../../node_modules/bootstrap/scss/bootstrap";

:root {
  --mdc-theme-secondary: #{$primary};
  --md-sys-color-primary: #{$primary};
  --md-sys-color-secondary: #{$secondary};
}
